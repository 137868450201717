import { RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eThemeSharedRouteNames } from '@abp/ng.theme.shared';

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.remove([eThemeSharedRouteNames.Administration]);
    routes.add([
      // Customer
      {
        path: '/pages/customer',
        name: '::Menu:customers',
        iconClass: 'team',
        requiredPolicy: 'Masters.Customers',
        order: 1,
      },
      // Vessel
      {
        path: '/pages/vessel',
        name: '::Menu:vessels',
        iconClass: 'crown',
        requiredPolicy: 'Masters.Vessels',
        order: 2,
      },
      // Vendor
      {
        path: '/pages/vendor',
        name: '::Menu:vendors',
        iconClass: 'deployment-unit',
        requiredPolicy: 'Masters.Vendors',
        order: 3,
      },
      {
        path: '',
        name: '::Menu:admin',
        iconClass: 'safety-certificate',
        layout: eLayoutType.application,
        order: 4,
      },
      {
        path: '',
        name: '::Menu:masterpages',
        iconClass: 'read',
        layout: eLayoutType.application,
        order: 5,
      },
      {
        path: '',
        name: '::Menu:mappings',
        iconClass: 'node-expand',
        layout: eLayoutType.application,
        order: 6,
      },
      {
        path: '',
        name: '::Menu:transactions',
        iconClass: 'profile',
        layout: eLayoutType.application,
        order: 7,
      },
      {
        path: '',
        name: '::Menu:system',
        iconClass: 'cog',
        layout: eLayoutType.application,
        order: 8,
      },
    ]);
  };
}
